<template>
  <v-scale-transition>
    <panel
      :breadcrumbs="breadcrumbs"
      :title="title"
    >
      <v-toolbar
        flat
      >
        <v-flex xs4>
          <v-select
            v-model="filter"
            :items="filters"
            item-text="text"
            item-value="value"
            label="Filter by"
          />
        </v-flex>
        <v-flex xs6>
          <v-select
            v-if="filter === 'stat'"
            v-model="stat"
            :items="status"
            item-text="text"
            item-value="value"
            label="Select status"
          />
          <v-text-field
            v-if="filter !== null && filter !== 'stat'"
            v-model="search"
            label="Enter search string"
            clearable
          />
        </v-flex>
        <v-card-actions>
          <v-spacer />
          <v-btn
            v-if="filter !== null"
            color="primary"
            dark
            class="mb-2"
            @click="refreshList()"
          >
            Search
          </v-btn>
        </v-card-actions>
        <v-dialog
          v-model="verifyDialog"
          max-width="800px"
        >
          <v-card>
            <v-card-title>
              <span class="headline">Verified SSM</span>
            </v-card-title>
            <v-alert
              :value="true"
              type="warning"
            >
              This action is irreversible!
              Are you sure you want to verified these SSM Documents?
            </v-alert>
            <v-card-actions>
              <v-spacer />
              <v-btn
                color="red darken-1"
                flat
                @click="verifyDealerSSM"
              >
                Verify SSM
              </v-btn>
              <v-btn
                color="blue darken-1"
                flat
                @click="closeAllDialogs(false)"
              >
                Cancel
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog
          v-model="rejectSsmDialog"
          max-width="800px"
        >
          <v-card>
            <v-card-title>
              <span class="headline">Reject News</span>
            </v-card-title>
            <v-alert
              :value="true"
              type="warning"
            >
              This action is irreversible!
              Are you sure you want to reject dealer SSM?
            </v-alert>
            <v-card-actions>
              <v-spacer />
              <v-btn
                color="red darken-1"
                flat
                @click="deleteDealerNews"
              >
                Reject SSM
              </v-btn>
              <v-btn
                color="blue darken-1"
                flat
                @click="closeAllDialogs(false)"
              >
                Cancel
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
      <v-data-table
        :headers="headers"
        :items="checks"
        :loading="loading"
        item-key="id"
        class="elevation-1"
        disable-initial-sort
      >
        <template #items="props">
          <td class="text-xs-left">
            {{ props.item.notify }}
          </td>
          <td class="text-xs-left">
            {{ props.item.dateUpload }}
          </td>
          <td class="text-xs-left">
            {{ props.item.dateVerified }}
          </td>
          <td class="text-xs-left">
            {{ props.item.dealer }}
          </td>
          <td class="text-xs-left">
            {{ props.item.status }}
          </td>
          <td class="text-xs-right">
            <v-btn
              :href="props.item.image"
              target="_blank"
              flat
            >
              <v-icon
                small
                color="grey"
              >
                mdi-download
              </v-icon>
            </v-btn>
          </td>
          <td class="justify-center layout px-0">
            <v-icon
              small
              class="mr-2"
              color="blue"
              @click="verifySsm(props.item)"
            >
              mdi-check-circle
            </v-icon>
            <v-icon
              small
              color="red lighten-2"
              @click="rejectSsm(props.item)"
            >
              mdi-close-circle
            </v-icon>
          </td>
        </template>
      </v-data-table>
      <v-snackbar
        v-model="snackbar"
        color="success"
        :timeout="5000"
      >
        {{ snackbarText }}
        <v-btn
          dark
          flat
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </v-snackbar>
      <v-snackbar
        v-model="snackbarError"
        color="error"
        :timeout="5000"
      >
        {{ snackbarText }}
        <v-btn
          dark
          flat
          @click="snackbarError = false"
        >
          Close
        </v-btn>
      </v-snackbar>
    </panel>
  </v-scale-transition>
</template>

<script>
import { createGetParams, createPostParams } from '@/rest'

const pageTitle = 'SSM Document Verification'

export default {
  components: {
    Panel: () => import(/* webpackChunkName: "panel" */ '@/components/Panel'),
  },
  data: () => ({
    title: pageTitle,
    alert: false,
    alertType: null,
    alertMessage: null,
    snackbar: false,
    snackbarError: false,
    snackbarText: null,
    rejectSsmDialog: false,
    verifyDialog: false,
    filter: null,
    filters: [
      {
        text: 'Dealer',
        value: 'dealer',
      },
      {
        text: 'Status',
        value: 'stat',
      },
    ],
    stat: null,
    status: [
      {
        text: 'Pending',
        value: '1',
      },
      {
        text: 'Reject',
        value: '2',
      },
      {
        text: 'Success',
        value: '3',
      },
    ],
    search: null,
    validNews: true,
    loading: false,
    breadcrumbs: [
      {
        text: 'DMT Kits', disabled: false, to: '/support/dmt_kits',
      },
      {
        text: pageTitle, disabled: true,
      },
    ],
    headers: [
      {
        text: 'Notify',
        align: 'left',
        sortable: true,
        value: 'notify',
      },
      {
        text: 'Date Upload',
        align: 'left',
        sortable: true,
        value: 'dateUpload',
      },
      {
        text: 'Date Verified',
        align: 'left',
        sortable: true,
        value: 'dateVerified',
      },
      {
        text: 'Dealer',
        align: 'left',
        sortable: true,
        value: 'dealer',
      },
      {
        text: 'Status',
        align: 'left',
        sortable: true,
        value: 'status',
      },
      {
        text: 'Document',
        align: 'center',
        sortable: true,
        value: 'image',
      },
      {
        text: 'Actions',
        align: 'center',
        sortable: false,
        value: 'id',
      },
    ],
    checks: [],
    editedIndex: -1,
    editedItem: {
      id: null,
      dealer_id: null,
      image: null,
      notify: null,
      dateUpload: null,
      dateVerfied: null,
      status: null,
    },
    defaultItem: {
      id: null,
      dealer_id: null,
      image: null,
      notify: null,
      dateUpload: null,
      dateVerfied: null,
      status: null,
    },
  }),
  computed: {
    noDataText: function () {
      return this.error ? this.error : "There's nothing to display."
    },
  },

  watch: {
    deleteNewsDialog (val) {
      val || this.closeAllDialogs(false)
      this.refreshList()
    },

    verifyDialog (val) {
      val || this.closeAllDialogs(false)
      this.refreshList()
    },
    filter: function () {
      (this.search || this.status) && this.resetList()
      this.search = null
      this.stat = null
    },
  },

  methods: {
    async getSsmDocument () {
      const filters = this.getFilters()
      if (!filters) return { items: [] }
      this.loading = true
      const parameters = {}
      Object.assign(parameters, filters)

      const apiParameters = createGetParams(parameters)
      try {
        this.source = this.$rest.CancelToken.source()
        Object.assign(apiParameters, { cancelToken: this.source.token })
        const response = await this.$rest.get('getSsmVerification.php', apiParameters)
        this.error = null
        this.loading = false
        return response.data
      } catch (error) {
        if (!this.$rest.isCancel(error)) {
          this.error = error.message
        }
        return { checks: [] }
      }
    },

    refreshList () {
      this.getSsmDocument().then(data => {
        this.checks = data.check
      })
    },
    resetList () {
      this.checks = []
    },
    rejectSsm (id) {
      this.editedIndex = this.checks.indexOf(id)
      this.editedItem = Object.assign({}, id)
      this.rejectSsmDialog = true
    },

    verifySsm (id) {
      this.editedIndex = this.checks.indexOf(id)
      this.editedItem = Object.assign({}, id)
      this.verifyDialog = true
    },

    getFilters () {
      const filterBy = {}
      if (this.filter === 'dealer' && this.search) {
        Object.assign(filterBy, { dealer: this.search })
      }
      if (this.filter === 'stat' && this.stat) {
        Object.assign(filterBy, { stat: this.stat })
      }
      return this.filter && (this.search || this.stat) ? filterBy : null
    },

    async deleteDealerNews () {
      try {
        const params = createPostParams({
          action: 'rejectSsm',
          payload: {
            id: this.editedItem.id,
          },
        })
        await this.$rest.post('postAction.php', params)

        this.snackbarText = 'You have successfully reject the dealer`s SSM'
        this.snackbar = true
        this.closeAllDialogs(true)
        this.refreshList()
      } catch (error) {
        let message = error.message
        if (error.response) {
          message += ': ' + error.response.data
        }
        this.showError(message)
      }
    },

    async verifyDealerSSM () {
      try {
        const params = createPostParams({
          action: 'verifySsm',
          payload: {
            id: this.editedItem.id,
          },
        })
        await this.$rest.post('postAction.php', params)
        this.snackbarText = 'You have successfully verify dealer`s SSM'
        this.snackbar = true
        this.closeAllDialogs(true)
        this.refreshList()
      } catch (error) {
        let message = error.message
        if (error.response) {
          message += ': ' + error.response.data
        }
        this.showError(message)
      }
    },

    closeAllDialogs (save) {
      this.rejectSsmDialog = false
      this.verifyDialog = false
      this.alert = false
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
        save && this.getSsmDocument()
      }, 300)
    },

    showError (message) {
      this.alertType = 'error'
      this.alertMessage = message
      this.alert = true
    },
  },
}
</script>

  <style>
    .custom-loader {
      animation: loader 1s infinite;
      display: flex;
    }
    @-moz-keyframes loader {
      from {
        transform: rotate(0);
      }
      to {
        transform: rotate(360deg);
      }
    }
    @-webkit-keyframes loader {
      from {
        transform: rotate(0);
      }
      to {
        transform: rotate(360deg);
      }
    }
    @-o-keyframes loader {
      from {
        transform: rotate(0);
      }
      to {
        transform: rotate(360deg);
      }
    }
    @keyframes loader {
      from {
        transform: rotate(0);
      }
      to {
        transform: rotate(360deg);
      }
    }

    .my-textarea { color: black }

    #editor {
    /* text-align: center; */
    color: black;
    background-color: #ffffff;
  }
  </style>
